import React from 'react';
import styled from 'styled-components';

import {
  BannerComponent,
  GuaranteeComponent,
  NavigationComponent,
  PartnershipComponent,
  ProductComponent,
  StoreComponent
} from '../../Components';

const Container = styled.div`
  overflow-x: hidden;
`;

const LandingPageScreen = (): React.JSX.Element => {
  return (
    <Container>
      <NavigationComponent />
      <BannerComponent />
      <GuaranteeComponent />
      <ProductComponent />
      <PartnershipComponent />
      <StoreComponent />
    </Container>
  );
};

export default LandingPageScreen;
