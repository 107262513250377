import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  padding-top: 15vh;
  padding-bottom: 15vh;
`;

const Title = styled.h1`
  font-family: 'Cabinet Grotesk', sans-serif;
  font-weight: 800;
`;

const Hr = styled.hr`
  border: none;
  height: 8px;
  background-color: #9d8c82;
  margin: 20px 0;
  width: 15%;
`;

const Caption = styled.h3`
  font-family: 'Cabinet Grotesk', sans-serif;
`;

const GuaranteeComponent = (): React.JSX.Element => {
  return (
    <Container>
      <center>
        <Title>Garansi Servis</Title>
        <Title> Seumur Hidup</Title>
        <Hr />
        <Caption>
          Garansi servis kacamata kamu seumur hidup tanpa syarat
        </Caption>
        <Caption>
          untuk segala kerusakan wajar
        </Caption>
      </center>
    </Container>
  );
};

export default GuaranteeComponent;
