import React from 'react';
import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';

const Title = styled.h3`
  font-family: 'Cabinet Grotesk', sans-serif;
  font-weight: bolder;
`;

const Address = styled.h5`
  font-family: 'Cabinet Grotesk', sans-serif;
`;

const ContainerStyled = styled.div`
  background-color: #E7E3DF;
  padding: 4vw 8vh !important;

  @media (max-width: 768px) {
    padding: 7vw 9vw !important;
  }
`;

const Copyright = styled.h5`
  font-family: 'Cabinet Grotesk', sans-serif;
`;

const ContactComponent = (): React.JSX.Element => {
  return (
    <ContainerStyled>
      <Row className='gy-5'>
        <Col md={6}>
          <Title className='mb-4'>Hubungi Kami</Title>
          <Address><b>Email.&nbsp;</b>
            <a
              href='mailto: services@150k.com'
              className='text-decoration-none text-black'
            >
              services@150k.com
            </a>
          </Address>
          <Address><b>Whatsapp.&nbsp;</b>
            <a
              href='https://wa.me/628116597722'
              className='text-decoration-none text-black'
              target='_blank'
            >
              0811-6597-722
            </a>
          </Address>
        </Col>
        <Col md={6}>
          <Title className='mb-4'>Alamat Utama</Title>
          <a className="text-decoration-none text-black" href="https://goo.gl/maps/kR6N18aRzCDqYY4j7" target="_blank">
            <Address>
              Jalan Karya Dame Nomor 25-D, Komplek Karya Quality No. 25-D, Karang Berombak
            </Address>
            <Address>
              Kecamatan Medan Kota, Kota
              Medan, Sumatera Utara 20117
            </Address>
          </a>
        </Col>
      </Row>
      <Copyright className='pt-5'>All Rights Reserved © 150K Optik</Copyright>
    </ContainerStyled>
  );
};

export default ContactComponent;
